<template>
  <CartSummary :items="items" :count="count" />
</template>

<script setup lang="ts">
defineComponent({
  name: 'CheckoutCartSummary',
});

const { items, count } = useCartAttributes();
</script>

<style lang="postcss" scoped>
.CheckoutCartSummary {
  @apply border border-t-0 border-primary-1-10 rounded-4xl;
  &__List {
    li + li {
      margin-top: 23px;
    }
  }
}

.CartItem__image {
  height: 70px;
  width: 70px;

  img {
    @apply w-full h-full object-contain;
  }
}
</style>

<i18n>
{
  "en": {
    "products": "Products",
    "qty": "Qty"
  },
  "ar": {
    "products": "المنتجات",
    "qty": "الكمية"
  }
}
</i18n>
