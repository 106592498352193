<template>
  <div data-cy="checkout-cart-summary" class="CheckoutCartSummary">
    <h2 class="text-left font-bold text-lg-1 font-body mt-14">
      {{ $t('products') }}
    </h2>
    <p class="text-left text-primary-1-60 text-sm mt-2">{{ $t('productsCount', { count }) }}</p>

    <ul class="CheckoutCartSummary__List mt-6">
      <li
        v-for="{
          id,
          quantity,
          brand,
          name,
          totalPrice,
          totalDiscount,
          image,
          oldPrice,
          stock,
          giftBoxMessage,
          url,
        } in items"
        :key="id"
      >
        <div class="flex items-center space-x-6">
          <AppLink :to="`/${url}`" class="CartItem__image relative">
            <picture>
              <img :src="image?.src || ''" :alt="image?.alt || ''" />
            </picture>
          </AppLink>
          <div class="flex justify-between w-full">
            <div class="flex flex-col w-full">
              <AppLink :to="`/${url}`" class="CartItem__name justify-self-start">
                <span v-if="brand" data-attr="cartItem__brandName__txt" class="block font-bold text-sm mb-1">{{
                  brand
                }}</span>
                <span data-attr="cartItem__productName__txt" class="font-display font-normal text-sm">{{ name }}</span>
                <span v-if="!stock" class="block mt-1 text-primary-1-40">{{ $t('outOfStockLabel') }}</span>
                <span v-if="stock && stock < quantity" class="block mt-1 text-primary-1-40">{{
                  $t('qtyUnavailableLabel')
                }}</span>
              </AppLink>
              <div class="w-full mt-2 flex gap-x-4">
                <div class="w-full flex items-center justify-between">
                  <p class="flex items-center gap-x-1.5">
                    <span class="uppercase text-primary-1-40 tracking-wide">{{ $t('qty') }}</span>
                    <span>.</span>
                    <span class="font-medium">{{ quantity }}</span>
                  </p>
                  <div class="flex items-center space-x-2.5">
                    <Money
                      v-if="(oldPrice ?? 0) * quantity !== (totalPrice ?? 0) - (totalDiscount ?? 0)"
                      :value="oldPrice ?? 0 * quantity"
                      class="line-through text-primary-1-40"
                    />

                    <p class="font-bold text-primary-1-100">
                      <Money :value="Number(totalPrice) - Number(totalDiscount)" />
                    </p>
                  </div>
                </div>
              </div>

              <section
                v-if="giftBoxMessage && giftBoxMessage !== 'N/A'"
                class="[ flex flex-col space-y-2.5 ] bg-primary-1-10 [ p-3.5 mt-4 ] col-span-2"
              >
                <header class="text-gray-90 font-body">
                  {{ $t('customMessage') }}
                </header>

                <div class="flex items-center oneLine font-bold">
                  <p class="">"{{ giftBoxMessage }}"</p>
                </div>
              </section>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import type { CartItem } from '@robustastudio/e-commerce/common';

defineComponent({
  name: 'CartSummary',
});

const { t: $t } = useI18n({ useScope: 'local' });

defineProps({
  items: {
    type: Array as PropType<CartItem[] | []>,
    default: () => [],
  },
  count: {
    type: Number,
    default: 0,
  },
});
</script>

<i18n>
{
  "en": {
    "products": "Products In Cart ",
    "qty": "Qty",
    "purchaseItems": "Purchase Items",
    "productsCount": "You have {count} product in your order | You have {count} products in your order",
    "customMessage": "CUSTOM MESSAGE"
  },
  "ar": {
    "products": "المنتجات",
    "purchaseItems": "المنتجات المشتراه",
    "productsCount": "لديك {count} منتج فى طلبك ",
    "qty": "الكمية",
    "customMessage": "رسالة مخصصة"
  }
}
</i18n>

<style lang="postcss" scoped>
.CheckoutCartSummary,
.OrderCartSummary {
  @apply p-6;

  &__List {
    li + li {
      @apply border-t border-primary-1-10;
      margin-block-start: 18px;
      padding-block-start: 20px;
    }
  }
}

@screen lg {
  .CheckoutCartSummary,
  .OrderCartSummary {
    @apply px-7;
    &::before {
      content: unset;
    }
  }
}

.CartItem {
  display: grid;
  grid-template-columns: 60px 1fr;
  grid-template-rows: auto;
  align-items: center;
  column-gap: 20px;

  &__image {
    @apply flex-shrink-0;
    width: 60px;
    height: 88px;

    img {
      @apply object-cover w-full h-full;
      border-radius: 12px;
    }
  }

  &__name {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.oneline {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>

<i18n>
{
  "en": {
    "outOfStockLabel": "Out of Stock"
  },
  "ar": {
    "outOfStockLabel": "نفذت الكمية"
  }
}
</i18n>
